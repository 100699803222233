import React, { useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import UIkit from "uikit"
import Markdown from "react-markdown"

const SliderSwitcherContent = ({ sliderSwitcherContent }) => {
  //console.log("sliderSwitcherContent: ", sliderSwitcherContent)
  const [hasMounted, setHasMounted] = React.useState(false)

  useEffect(() => {
    // trigger another render, reconciliation after the first mount, updating the dom with regards to rehydration
    setHasMounted(true)

    // now everything is syncned
    const util = UIkit.util

    // init switcher
    const switchEl = UIkit.switcher(".uk-subnav", {
      animation: "uk-animation-fade",
      swiping: true,
    })

    const sliderEl = UIkit.slider("[uk-slider]")

    // get slider elements
    const sliderItems = util.$$(".uk-slider-items li")

    // add active class to first slider element
    util.addClass(sliderItems[0], "active")

    // add event listener for each element
    sliderItems.forEach((item, index) => {
      UIkit.util.on(item, "itemshow", function () {
        switchEl.show(index)
        sliderEl.show(index)
        util.removeClass(sliderItems, "active")
        util.addClass(item, "active")
      })
      // go to switcher index based on slider element index
      item.addEventListener("click", () => {
        switchEl.show(index)
        //sliderEl.show(index);
        util.removeClass(sliderItems, "active")
        util.addClass(item, "active")
      })
    })
  }, [])

  if (!hasMounted) {
    setHasMounted(true)
  }

  /* if (!hasMounted) {
    return null;
  } */


  return (
    <>
      <div
        className="uk-position-relative uk-visible-toggle uk-light"
        uk-slider="autoplay:true;"
        style={{ color: "red" }}
      >
        <ul className="uk-slider-items uk-grid uk-grid-small uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-5@m">
          {sliderSwitcherContent.map((item, index) => {
            //console.log(item.image.localFile)
            return (
              <li key={index}>
                {item.image && (
                  <GatsbyImage
                    image={getImage(
                      item.image.localFile.childImageSharp.gatsbyImageData
                    )}
                    alt={item.image.alternativeText}
                    title={item.image.alternativeText}
                    style={{ maxHeight: "100%" }}
                  />
                )}
                {/* <div className="uk-position-center uk-panel">
                  <h1>{item.sequence}</h1>
                </div> */}
              </li>
            )
          })}
        </ul>

        <a
          className="uk-position-center-left uk-position-small uk-hidden-hover"
          href="/#"
          aria-label="Previous"
          uk-slidenav-previous="true"
          uk-slider-item="previous"
        >
          {null}
        </a>
        <a
          className="uk-position-center-right uk-position-small uk-hidden-hover"
          href="/#"
          aria-label="Next"
          uk-slidenav-next="true"
          uk-slider-item="next"
        >
          {null}
        </a>
      </div>
      <hr />
      <div>
        <ul className="uk-subnav uk-hidden">
          <li>
            <a href="/">Item</a>
          </li>
          <li>
            <a href="/#">Item</a>
          </li>
          <li>
            <a href="/#">Item</a>
          </li>
          <li>
            <a href="/#">Item</a>
          </li>
          <li>
            <a href="/#">Item</a>
          </li>
          <li>
            <a href="/#">Item</a>
          </li>
        </ul>

        <ul className="uk-switcher uk-margin">
          {sliderSwitcherContent.map((item, index) => {
            //console.log(item.image.localFile)
            //console.log("item: ", item)

            return (
              <li key={index}>
                <div className="pooche-card">
                  <div className="uk-card uk-card-body uk-card-default">
                    <div>
                      <h5>{item.title}</h5>
                    </div>
                    <Markdown
                      //className="uk-padding-remove-bottom"
                      //children={item.description.data.childMarkdownRemark.html}
                      children={item.description.data.description}
                    />
                    {item.enabled ? (
                      <Link
                        to={item.link}
                        title="text"
                        alt="text"
                        className="uk-button uk-button-default uk-button-small uk-margin-small-bottom uk-text-small"
                        data-uk-icon="arrow-right"
                        style={{ borderColor: "#687169", color: "#687169", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                      >
                        <span className="uk-margin-small-right">{item.buttonName}</span>
                      </Link>
                    ) : (
                      <button
                        //to={item.link}
                        className="uk-button uk-button-primary uk-border-pill uk-margin-medium-bottom"
                        //type="button"
                        disabled
                        title="Coming soon - button disabled"
                        data-uk-icon="arrow-right"
                        style={{ borderColor: "#617F7F", color: "#617F7F" }}
                      >
                        <span className="uk-margin-small-right">{item.buttonName}</span>
                      </button>
                    )}
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default SliderSwitcherContent
