import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Headings from "../components/headings"
import SliderSwitcherContent from "../components/slider-switcher-content"
import CtaSlider from "../components/cta-slider"
import PageAdvert from "../components/page-advert"
import Markdown from "react-markdown"

const PartnersPage = () => {
    const { strapiPartner, allStrapiSliderSwitcherContentSection } = useStaticQuery(graphql`
    query {
      strapiPartner {
        hero {
            ...StrapiHero
        }
        headings {
            title
            description
        }
        contentTop {
          data {
            contentTop
          }
        }
        pageAd {
          ...PageAdverts
        }
        content {
          data {
            content
          }
        }
        callToAction {
          ...StrapiCallToAction
        }
        seo {
          ...StrapiSeo
          metaSocial {
            socialNetwork
            title
            description
          }
          structuredData {
            _type
            name
            url
            contactPoint {
              _type
              availableLanguage
              contactType
              telephone
            }
            sameAs
          }
        }         
      }
      allStrapiSliderSwitcherContentSection(
        filter: { location: { eq: "partners-page" }, featured: { eq: true }, enabled: { eq: true } }
        sort: { order: ASC, fields: sequence }
      ) {
        ...SliderSwitcherContentSections
      }  
    }
  `)

  //console.log("strapiPartner: ", strapiPartner)

  const structuredData = strapiPartner.seo.structuredData

  const imageUrl = `${strapiPartner.seo.metaImage.localFile.publicURL}`

  const { hero, headings } = strapiPartner

  const sliderSwitcherContent = allStrapiSliderSwitcherContentSection.nodes
  //console.log("sliderSwitcherContent: ", sliderSwitcherContent)

  const LikeMindedPartners = strapiPartner.pageAd.page_adverts[0]
  //const qualityServicesDefineUs = strapiPartner.pageAd.page_adverts[1]
  const significanceOfNetworking = strapiPartner.pageAd.page_adverts[1]
  const commitmentFromOurDirectors = strapiPartner.pageAd.page_adverts[2]
  //console.log("strapiPartner - pageAds: ", advertimageleft)

  const callToAction = strapiPartner.callToAction
  //console.log(" strapiPartner callToAction: ", callToAction)

  const callToActionSections = strapiPartner.callToAction.call_to_action_sections
  //console.log("strapiPartner callToActionSections: ", callToActionSections)

 
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={strapiPartner.seo.metaTitle}
        description={strapiPartner.seo.metaDescription}
        image={imageUrl}
        post={false}
        metaSocial={strapiPartner.seo.metaSocial}
        metaRobots={strapiPartner.seo.metaRobots}
      />

      <Hero hero={hero} />

      <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container uk-margin-small-bottom">
          <Headings title={headings.title} description={headings.description} />
        </div>
      </section>

      <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container" style={{ marginTop: "10px"}}>
          <Markdown children={strapiPartner.contentTop.data.contentTop} />
        </div>
      </section>

      <section className="uk-section uk-section-default uk-padding-remove">
        <div style={{ marginTop: "38px", marginBottom: "38px"}}>
          <PageAdvert advert={LikeMindedPartners} background="uk-background-default" />
        </div>        
      </section>

      <section className="uk-section uk-section-muted uk-padding-remove">
        <div className="uk-container" style={{ marginTop: "20px",  marginBottom: "40px"}}>
          <div className="uk-margin-small-bottom">
            <h3 className="uk-margin-remove" style={{marginBottom: "20px"}}>Quality Services Define Us</h3>
          </div>
          <div className=" uk-margin-small-bottom">
            <h5 className="uk-margin-remove" style={{ color: "#687169", fontWeight: "500" }} >Top-quality services are non-negotiable at JWRs</h5>
          </div>
          <div className="uk-margin-small-top">
            <p>Top-quality services are non-negotiable at JWRs. By partnering with organisations that uphold the same standard of excellence, 
              we amplify the impact of our collective efforts. Our commitment to providing the best for our clients is reflected in the calibre 
              of our partners. Together, we set new benchmarks, ensuring that our clients receive nothing short of excellence.</p>
          </div>
          <div className="uk-text-center">
            <StaticImage
            src="../images/d-and-d-logo.jpg"
            alt="d and d project solutions logo"
            formats={["AUTO", "WEBP", "AVIF"]}
            placeholder="blurred"
            title="d and d project solutions logo"
            //style={{ maxWidth: "850px" }}
          />
          </div>          
          {/* <div className="uk-text-center">
            <h6 className="" >caption</h6>
          </div> */}
      </div>
    </section>
      
      {/* <section className="uk-section uk-section-muted uk-padding-remove">
        <div style={{ marginTop: "38px", marginBottom: "38px"}}>
          <PageAdvert advert={qualityServicesDefineUs} background="uk-background-muted" />
        </div>        
      </section> */}

      <section className="uk-section uk-section-default uk-padding-remove">
        <div style={{ marginTop: "38px", marginBottom: "38px"}}>
          <PageAdvert advert={significanceOfNetworking} background="uk-background-default" />
        </div>        
      </section>

      <section className="uk-section uk-section-muted uk-padding-remove">
        <div style={{ marginTop: "38px", marginBottom: "38px"}}>
        <PageAdvert advert={commitmentFromOurDirectors} background="uk-background-muted" />
        </div>        
      </section>
      
      <section className="uk-section uk-section-default uk-padding-remove" style={{ marginTop: "20px" }}>
        <div className="uk-container " >
          <Markdown children={strapiPartner.content.data.content} />
        </div>
      </section>

      <section className="uk-section uk-section-default uk-padding-remove" >
        <div className="uk-container" style={{ marginBottom: "20px" }}>
          <Headings
            title="Our Partnerships"
            description="As we prepare to welcome you into the heart of our operations, take a moment to glimpse into the essence of JWRs through the lens of our carefully curated partnerships. Before you meet the individuals and businesses we proudly collaborate with, understand that each connection is a reflection of our values, a commitment to excellence, and a shared vision for success. Step into a world where collaboration isn't just a strategy—it's the cornerstone of who we are."
          />
          <div className="" style={{ marginTop: "20px"}}>
            <SliderSwitcherContent
              sliderSwitcherContent={sliderSwitcherContent}
            />
          </div>
          
        </div>
      </section>

      <section className="uk-section uk-section-muted uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
            <Headings
              title={callToAction.title}
              description={callToAction.description}
            />
          <div className="uk-container uk-margin">
            <CtaSlider callToActionSections={callToActionSections} />
          </div>
        </div>
      </section>
    </>
  )
}

export default PartnersPage
